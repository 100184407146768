(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('TkbPaymentController', TkbPaymentController);

    TkbPaymentController.$inject = ['$state', '$window', '$http', 'DateUtils', 'TkbPayment', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'ProfileIdByPhone'];

    function TkbPaymentController($state, $window, $http, DateUtils, TkbPayment, ParseLinks, AlertService, paginationConstants, pagingParams, ProfileIdByPhone) {

        var vm = this;
        vm.tkbPayments = [];
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.openProfile = openProfile;
        vm.search = search;
        vm.excel = excel;
        vm.clear = clear;
        vm.getPaymentState = getPaymentState;
        vm.searchQuery = {
            userPhone: pagingParams.userPhone,
            externalId: pagingParams.externalId,
            account: pagingParams.account,
            state: pagingParams.state,
            creditState: pagingParams.creditState,
            gateway: pagingParams.gateway,
            transactionStatus: pagingParams.transactionStatus,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate,
            from: pagingParams.from,
            to: pagingParams.to
        };

        loadAll();

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.from = false;
        vm.datePickerOpenStatus.to = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getDateTimeFromPicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
        }

        function loadAll() {
            TkbPayment.query({
                userPhone: vm.searchQuery.userPhone,
                externalId: vm.searchQuery.externalId,
                account: vm.searchQuery.account,
                state: vm.searchQuery.state,
                creditState: vm.searchQuery.creditState,
                gateway: vm.searchQuery.gateway,
                transactionStatus: vm.searchQuery.transactionStatus,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                from: vm.searchQuery.from,
                to: vm.searchQuery.to,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tkbPayments = data;
                vm.page = pagingParams.page;
                setTimeout(function () {
                    enableClipboard();
                }, 0);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                userPhone: vm.searchQuery.userPhone,
                externalId: vm.searchQuery.externalId,
                account: vm.searchQuery.account,
                state: vm.searchQuery.state,
                creditState: vm.searchQuery.creditState,
                gateway: vm.searchQuery.gateway,
                transactionStatus: vm.searchQuery.transactionStatus,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                from: vm.searchQuery.from,
                to: vm.searchQuery.to
            });
        }

        function search() {
            getDateTimeFromPicker();
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.searchQuery = {
                userPhone: null,
                externalId: null,
                account: null,
                state: null,
                creditState: null,
                gateway: 'ALL',
                transactionStatus: "UNKNOWN",
                fromDate: null,
                toDate: null,
                from: null,
                to: null
            };
            vm.transition();
        }

        function excel() {
            getDateTimeFromPicker();
            var fromDate = vm.searchQuery.fromDate === "" ? null : DateUtils.converterDateTimeFormat(vm.searchQuery.fromDate);
            var toDate = vm.searchQuery.toDate === "" ? null : DateUtils.converterDateTimeFormat(vm.searchQuery.toDate);
            var data = (vm.searchQuery.userPhone == null ? "" : "userPhone=" + vm.searchQuery.userPhone)
                + (vm.searchQuery.externalId == null ? '' : "&externalId=" + vm.searchQuery.externalId)
                + (vm.searchQuery.account == null ? '' : "&account=" + vm.searchQuery.account)
                + (vm.searchQuery.state == null ? '' : "&state=" + vm.searchQuery.state)
                + (vm.searchQuery.creditState == null ? '' : "&creditState=" + vm.searchQuery.creditState)
                + (vm.searchQuery.gateway == null ? 'ALL' : "&gateway=" + vm.searchQuery.gateway)
                + (vm.searchQuery.transactionStatus == null ? 'UNKNOWN' : "&transactionStatus=" + vm.searchQuery.transactionStatus)
                + "&fromDate=" + fromDate
                + '&toDate=' + toDate;
            $http({
                url: 'api/tkb-payments-excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                saveAs(blob, 'tkb-payments-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {
            });

        }

        function openProfile(phoneNumber) {
            ProfileIdByPhone.get({phoneNumber: phoneNumber}, onSuccess);

            function onSuccess(data) {
                var url = $state.href('profile-detail', {id: data.profileId});
                $window.open(url,'_blank');
            }
        }

        function getPaymentState(externalId) {
            TkbPayment.getPaymentState({externalId: externalId}, onSuccess, onError);

            function onSuccess() {
                vm.externalId = null;
                loadAll();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function enableClipboard() {
            var clipboardClass = document.getElementsByClassName('mt-clipboard');
            var clipboard = new Clipboard(clipboardClass);
        }

    }
})();
