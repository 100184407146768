(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PaynetModeController', PaynetModeController);

    PaynetModeController.$inject = ['$scope', '$state', 'PaynetMode', 'PaynetModeSearch', 'Config'];

    function PaynetModeController($scope, $state, PaynetMode, PaynetModeSearch, Config) {
        var vm = this;

        vm.paymentModes = [];
        vm.allConfigs = {};
        vm.allPaymentModes = {};
        vm.paynetMode = null;
        vm.save = save;
        vm.loadAll = loadAll;
        vm.saveConfig = saveConfig;

        vm.modes = [
            {value: 'AUTO', text: 'AUTO'},
            {value: 'MANUAL', text: 'MANUAL'},
            {value: 'DISABLED', text: 'DISABLED'}
        ];

        vm.ruTelGateways = [
            {value: 'VSEPLATEZHI', text: 'VSEPLATEZHI'},
            {value: 'DISABLED', text: 'DISABLED'}
        ];

        vm.tkbModes = [
            {value: 'ENABLED', text: 'ENABLED'},
            {value: 'UZCARD', text: 'UZCARD'},
            {value: 'HUMO', text: 'HUMO'},
            {value: 'DISABLED', text: 'DISABLED'}
        ];

        vm.uniredGateways = [
            {value: 'ALL', text: 'ALL'},
            {value: 'TKB', text: 'TKB'},
            {value: 'TKB_IN_BOTH', text: 'TKB_IN_BOTH'},
            {value: 'MTS', text: 'MTS'},
            {value: 'PAYGINE', text: 'PAYGINE'}
        ];

        vm.uniredpaymentModes = [
            {value: 'ENABLED', text: 'ENABLED'},
            {value: 'DISABLED', text: 'DISABLED'}
        ];

        vm.linkedCards = [
            {value: 'TKB', text: 'TKB'},
            {value: 'DISABLED', text: 'DISABLED'}
        ];

        vm.unlinkedCards = [
            {value: 'TKB', text: 'TKB'},
            {value: 'MTS', text: 'MTS'},
            {value: 'PAYGINE', text: 'PAYGINE'},
            {value: 'DISABLED', text: 'DISABLED'}
        ];

        vm.savedCards = [
            {value: 'PAYGINE', text: 'PAYGINE'},
            {value: 'DISABLED', text: 'DISABLED'}
        ];

        vm.bankApps = [
            {value: 'SBP', text: 'SBP'},
            {value: 'DISABLED', text: 'DISABLED'}
        ];

        vm.checkCards = [
            {value: 'TKB', text: 'TKB'},
            {value: 'MTS', text: 'MTS'},
            {value: 'PAYGINE', text: 'PAYGINE'},
            {value: 'SBP', text: 'SBP'},
            {value: 'DISABLED', text: 'DISABLED'}
        ];

        loadAll();

        function loadAll() {
            PaynetMode.query(function (result) {
                vm.paymentModes = result;
                for (var i = 0; i < vm.paymentModes.length; i++) {
                    vm.allPaymentModes[vm.paymentModes[i].name] = vm.paymentModes[i];
                }
            });

            Config.query(function (result) {
                vm.configs = result;
                for (var i = 0; i < vm.configs.length; i++) {
                    vm.allConfigs[vm.configs[i].name] = vm.configs[i];
                }
            });
        }

        function save(name) {
            vm.isSaving = true;
            if (vm.allPaymentModes[name].id !== null) {
                PaynetMode.update(vm.allPaymentModes[name], onSaveSuccess, onSaveError);
            } else {
                PaynetMode.save(vm.allPaymentModes[name], onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            loadAll();
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function saveConfig(name) {
            vm.isSaving = true;
            if (vm.allConfigs[name].id !== null) {
                Config.update(vm.allConfigs[name], onSaveSuccess, onSaveError);
            } else {
                Config.save(vm.allConfigs[name], onSaveSuccess, onSaveError);
            }
        }
    }
})();
