(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', '$state', 'SmsRussiaBalance', 'SmsRussiaLimit', 'HermesBalance', 'CustomerStats', 'TotalTransfer', 'TotalPayment', 'Config'];

    function HomeController($scope, Principal, $state, SmsRussiaBalance, SmsRussiaLimit, HermesBalance, CustomerStats, TotalTransfer, TotalPayment, Config) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.register = register;
        vm.smsRussiaBalance = SmsRussiaBalance.get();
        vm.smsRussiaLimit = SmsRussiaLimit.get();
        vm.getUsedSmsPercentage = getUsedSmsPercentage;
        vm.allConfigs = {}
        vm.transferNewUsers = [];
        vm.paymentNewUsers = [];
        vm.findTransferNewUsersByDate = findTransferNewUsersByDate;
        vm.findPaymentNewUsersByDate = findPaymentNewUsersByDate;

        if (Principal.hasAnyAuthority(['ROLE_MERCHANT'])) {
            $state.go('merchant');
        }

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        getAccount();
        getHermesBalance();
        loadConfigs();
        getNewUsers();
        getTotalTransfers();
        getTransferNewUsers();
        getTotalPayments();
        getPaymentNewUsers();


        function loadConfigs() {
            Config.query(function (result) {
                vm.configs = result;
                for (var i = 0; i < vm.configs.length; i++) {
                    vm.allConfigs[vm.configs[i].name] = vm.configs[i];
                }
                vm.searchQuery = null;
            });
        }
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function register() {
            $state.go('register');
        }

        function getUsedSmsPercentage(usedSms) {
            return usedSms * 100 / vm.smsRussiaLimit.total_limit;
        }

        function getHermesBalance() {
            HermesBalance.get(function (data) {
                angular.forEach(data.Balances, function (v, k) {
                    if (v.Currency === "RUB") {
                       vm.hermesBalanceRub = v.Balance;
                    }
                    if (v.Currency === "USD") {
                       vm.hermesBalanceUsd = v.Balance;
                    }
                });
            });
        }

        function getNewUsers() {
            CustomerStats.get(onSuccess);

            function onSuccess(data) {
                vm.newCustomers = data;
            }
        }

        function getTotalTransfers() {
            TotalTransfer.get(onSuccess);

            function onSuccess(data) {
                vm.transfers = data;
            }
        }

        function getTransferNewUsers() {
            TotalTransfer.getNewUsers(onSuccess);

            function onSuccess(data) {
                vm.transferNewUsers = data;
            }
        }

        function findTransferNewUsersByDate(date){
            var result = 0;
            vm.transferNewUsers.forEach(function(value) {
                if (value.date === date) {
                    result += value.count;
                }
            });
            return result;
        }

        function getTotalPayments() {
            TotalPayment.get(onSuccess);

            function onSuccess(data) {
                vm.payments = data;
            }
        }

        function getPaymentNewUsers() {
            TotalPayment.getNewUsers(onSuccess);

            function onSuccess(data) {
                vm.paymentNewUsers = data;
            }
        }

        function findPaymentNewUsersByDate(date){
            var result = 0;
            vm.paymentNewUsers.forEach(function(value) {
                if (value.date === date) {
                    result += value.count;
                }
            });
            return result;
        }
    }
})();
