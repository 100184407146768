(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('UzbCardController', UzbCardController);

    UzbCardController.$inject = ['$state', '$window', 'UzbCard', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'ProfileIdByPhone'];

    function UzbCardController($state, $window, UzbCard, ParseLinks, AlertService, paginationConstants, pagingParams, ProfileIdByPhone) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.search = search;
        vm.clear = clear;
        vm.openProfile = openProfile;
        vm.searchQuery = {
            userPhone: pagingParams.userPhone,
            owner: pagingParams.owner,
            cardNumber: pagingParams.cardNumber,
            phone: pagingParams.phone,
            blocked: pagingParams.blocked
        };
        loadAll();

        function loadAll () {
            UzbCard.query({
                userPhone: vm.searchQuery.userPhone,
                owner: vm.searchQuery.owner,
                cardNumber: vm.searchQuery.cardNumber,
                phone: vm.searchQuery.phone,
                blocked: vm.searchQuery.blocked,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.uzbCards = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                userPhone: vm.searchQuery.userPhone,
                owner: vm.searchQuery.owner,
                cardNumber: vm.searchQuery.cardNumber,
                phone: vm.searchQuery.phone,
                blocked: vm.searchQuery.blocked
            });
        }

        function search() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.searchQuery = {
                userPhone: null,
                owner: null,
                cardNumber: null,
                phone: null,
                blocked: -1
            };
            vm.transition();
        }

        function openProfile(phoneNumber) {
            ProfileIdByPhone.get({phoneNumber: phoneNumber}, onSuccess);

            function onSuccess(data) {
                var url = $state.href('profile-detail', {id: data.profileId});
                $window.open(url, '_blank');
            }
        }
    }
})();
